import capitalize from 'lodash/capitalize';
import upperCase from 'lodash/upperCase';
import truncate from 'lodash/truncate';
import moment from 'moment';
import numeral from 'numeral';
numeral.register('locale', 'es', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
});
numeral.locale('es');

const formatDate = (iso8601) => moment(iso8601).utc().format('DD/MM/YYYY');
const formatDateTime = (iso8601) => moment(iso8601).utc().format('DD/MM/YYYY HH:mm');
const formatDateMonthYear = (iso8601) => moment(iso8601).utc().format('MM/YYYY');
const formatDateYear = (iso8601) => moment(iso8601).utc().format('YYYY');
const formatDateReports = (iso8601) => moment(iso8601).utc().format('DD/MM/YYYY');

export default {
  install(Vue) {
    Vue.filter('dateMonthYear', function (iso8601) {
      if (!iso8601) {
        return '';
      }
      return formatDateMonthYear(iso8601);
    });
    Vue.filter('dateYear', function (iso8601) {
      if (!iso8601) {
        return '';
      }
      return formatDateYear(iso8601);
    });
    Vue.filter('reportsUtcDate', function (iso8601) {
      if (!iso8601) {
        return '';
      }
      return formatDateReports(iso8601);
    });

    Vue.filter('date', function (iso8601) {
      if (!iso8601) {
        return '';
      }
      return formatDate(iso8601);
    });
    Vue.filter('dateTime', function (iso8601) {
      if (!iso8601) {
        return '';
      }
      return formatDateTime(iso8601);
    });
    Vue.filter('timePeriodType', function (iso8601, periodType) {
      if (periodType == 'months') {
        return moment(iso8601).format('MM/YYYY');
      } else if (periodType == 'days') {
        return moment(iso8601).format('DD/MM/YYYY');
      } else if (periodType == 'hours') {
        return moment(iso8601).format('DD/MM/YYYY hh');
      } else if (periodType == 'minutes') {
        return moment(iso8601).format('DD/MM/YYYY hh:mm');
      } else {
        return '';
      }
    });
    Vue.filter('capitalize', (string) => capitalize(string));
    Vue.filter('upperCase', (string) => upperCase(string));
    Vue.filter('round', function (value) {
      if (!value) {
        return 0;
      }
      return numeral(value).format('0,0.000');
    });
    Vue.filter('integerFormat', function (value) {
      if (!value) {
        value = 0;
      }
      return numeral(value).format('0,0');
    });
    Vue.filter('floatFormatCommons', function (value) {
      if (!value) {
        value = 0;
      }
      return numeral(value).format('0,0.00');
    });
    Vue.filter('floatFormatReports', function (value) {
      if (!value) {
        value = 0;
      }
      return numeral(value).format('0,0.000');
    });
    Vue.filter('truncate', function (string, length, omission) {
      return truncate(string, {
        length: length ? length : 255,
        omission: omission ? omission : '...',
      });
    });
    Vue.filter('conversionRate', function (conversions, clicks) {
      if (clicks == 0) return 'N/A';
      return (conversions / clicks) * 100;
    });
    Vue.filter('epc', function (clicks, payout) {
      if (payout == 0) return 0;
      return Number(clicks) / Number(payout);
    });
    Vue.filter('formatCurrency', function (value) {
      if (!value) {
        value = 0;
      }
      return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).replace('$', '');
    });
  },
};

export default {
  list: [
    {
      name: 'Albania Lek',
      _id: 'ALL',
      symbol: '&#76;&#101;&#107;',
    },
    {
      name: 'Afghanistan Afghani',
      _id: 'AFN',
      symbol: '&#1547;',
    },
    {
      name: 'Argentina Peso',
      _id: 'ARS',
      symbol: '&#65;&#82;&#36;',
    },
    {
      name: 'Aruba Guilder',
      _id: 'AWG',
      symbol: '&#65;&#102;&#108;&#46;',
    },
    {
      name: 'Australia Dollar',
      _id: 'AUD',
      symbol: '&#65;&#36;',
    },
    {
      name: 'Azerbaijan New Manat',
      _id: 'AZN',
      symbol: '&#8380;',
    },
    {
      name: 'Bahamas Dollar',
      _id: 'BSD',
      symbol: '&#66;&#83;&#36;',
    },
    {
      name: 'Barbados Dollar',
      _id: 'BBD',
      symbol: '&#66;&#66;&#68;&#36;',
    },
    {
      name: 'Belarus Ruble',
      _id: 'BYR',
      symbol: '&#112;&#46;',
    },
    {
      name: 'Belize Dollar',
      _id: 'BZD',
      symbol: '&#66;&#90;&#36;',
    },
    {
      name: 'Bermuda Dollar',
      _id: 'BMD',
      symbol: '&#66;&#77;&#36;',
    },
    {
      name: 'Bolivia Boliviano',
      _id: 'BOB',
      symbol: '&#36;&#98;',
    },
    {
      name: 'Bosnia and Herzegovina Convertible Marka',
      _id: 'BAM',
      symbol: '&#75;&#77;',
    },
    {
      name: 'Botswana Pula',
      _id: 'BWP',
      symbol: '&#80;',
    },
    {
      name: 'Bulgaria Lev',
      _id: 'BGN',
      symbol: '&#1083;&#1074;',
    },
    {
      name: 'Brazil Real',
      _id: 'BRL',
      symbol: '&#82;&#36;',
    },
    {
      name: 'Brunei Darussalam Dollar',
      _id: 'BND',
      symbol: '&#66;&#36;',
    },
    {
      name: 'Cambodia Riel',
      _id: 'KHR',
      symbol: '&#6107;',
    },
    {
      name: 'Canada Dollar',
      _id: 'CAD',
      symbol: '&#67;&#65;&#36;',
    },
    {
      name: 'Cayman Islands Dollar',
      _id: 'KYD',
      symbol: '&#67;&#73;&#36;',
    },
    {
      name: 'Chile Peso',
      _id: 'CLP',
      symbol: '&#67;&#76;&#36;',
    },
    {
      name: 'China Yuan Renminbi',
      _id: 'CNY',
      symbol: '&#67;&#78;&#165;',
    },
    {
      name: 'Colombia Peso',
      _id: 'COP',
      symbol: '&#67;&#79;&#76;&#36;',
    },
    {
      name: 'Costa Rica Colon',
      _id: 'CRC',
      symbol: '&#8353;',
    },
    {
      name: 'Croatia Kuna',
      _id: 'HRK',
      symbol: '&#107;&#110;',
    },
    {
      name: 'Cuba Peso',
      _id: 'CUP',
      symbol: '&#67;&#85;&#80;&#36;',
    },
    {
      name: 'Czech Republic Koruna',
      _id: 'CZK',
      symbol: '&#75;&#269;',
    },
    {
      name: 'Denmark Krone',
      _id: 'DKK',
      symbol: '&#107;&#114;',
    },
    {
      name: 'Dominican Republic Peso',
      _id: 'DOP',
      symbol: '&#82;&#68;&#36;',
    },
    {
      name: 'East Caribbean Dollar',
      _id: 'XCD',
      symbol: '&#69;&#67;&#36;',
    },
    {
      name: 'Egypt Pound',
      _id: 'EGP',
      symbol: '&#163;&#69;',
    },
    {
      name: 'El Salvador Colon',
      _id: 'SVC',
      symbol: '&#8353;&#83;&#86;',
    },
    {
      name: 'Estonia Kroon',
      _id: 'EEK',
      symbol: '&#69;&#69;&#75;',
    },
    {
      name: 'Euro Member Countries',
      _id: 'EUR',
      symbol: '&#8364;',
    },
    {
      name: 'Falkland Islands (Malvinas) Pound',
      _id: 'FKP',
      symbol: '&#163;&#70;',
    },
    {
      name: 'Fiji Dollar',
      _id: 'FJD',
      symbol: '&#70;&#74;&#36;',
    },
    {
      name: 'Ghana Cedis',
      _id: 'GHC',
      symbol: '&#8373;',
    },
    {
      name: 'Gibraltar Pound',
      _id: 'GIP',
      symbol: '&#66;&#83;&#36;',
    },
    {
      name: 'Guatemala Quetzal',
      _id: 'GTQ',
      symbol: '&#81;',
    },
    {
      name: 'Guernsey Pound',
      _id: 'GGP',
      symbol: '&#163;&#71;',
    },
    {
      name: 'Guyana Dollar',
      _id: 'GYD',
      symbol: '&#71;&#89;&#36;',
    },
    {
      name: 'Honduras Lempira',
      _id: 'HNL',
      symbol: '&#76;',
    },
    {
      name: 'Hong Kong Dollar',
      _id: 'HKD',
      symbol: '&#72;&#75;&#36;',
    },
    {
      name: 'Hungary Forint',
      _id: 'HUF',
      symbol: '&#70;&#116;',
    },
    {
      name: 'Iceland Krona',
      _id: 'ISK',
      symbol: '&#107;&#114;',
    },
    {
      name: 'India Rupee',
      _id: 'INR',
      symbol: '&#8377;',
    },
    {
      name: 'Indonesia Rupiah',
      _id: 'IDR',
      symbol: '&#82;&#112;',
    },
    {
      name: 'Iran Rial',
      _id: 'IRR',
      symbol: '&#65020;',
    },
    {
      name: 'Isle of Man Pound',
      _id: 'IMP',
      symbol: '&#163;&#77;',
    },
    {
      name: 'Israel Shekel',
      _id: 'ILS',
      symbol: '&#8362;',
    },
    {
      name: 'Jamaica Dollar',
      _id: 'JMD',
      symbol: '&#74;&#36;',
    },
    {
      name: 'Japan Yen',
      _id: 'JPY',
      symbol: '&#165;',
    },
    {
      name: 'Jersey Pound',
      _id: 'JEP',
      symbol: '&#163;&#74;',
    },
    {
      name: 'Kazakhstan Tenge',
      _id: 'KZT',
      symbol: '&#8376;',
    },
    {
      name: 'Korea (North) Won',
      _id: 'KPW',
      symbol: '&#8361;',
    },
    {
      name: 'Korea (South) Won',
      _id: 'KRW',
      symbol: '&#8361;',
    },
    {
      name: 'Kyrgyzstan Som',
      _id: 'KGS',
      symbol: '&#1057;',
    },
    {
      name: 'Laos Kip',
      _id: 'LAK',
      symbol: '&#8365;',
    },
    {
      name: 'Latvia Lat',
      _id: 'LVL',
      symbol: '&#76;&#115;',
    },
    {
      name: 'Lebanon Pound',
      _id: 'LBP',
      symbol: '&#76;&#163;',
    },
    {
      name: 'Liberia Dollar',
      _id: 'LRD',
      symbol: '&#76;&#36;',
    },
    {
      name: 'Lithuania Litas',
      _id: 'LTL',
      symbol: '&#76;&#116;',
    },
    {
      name: 'Macedonia Denar',
      _id: 'MKD',
      symbol: '&#1076;&#1077;&#1085;',
    },
    {
      name: 'Malaysia Ringgit',
      _id: 'MYR',
      symbol: '&#82;&#77;',
    },
    {
      name: 'Mauritius Rupee',
      _id: 'MUR',
      symbol: '&#8360;&#77;',
    },
    {
      name: 'Mexico Peso',
      _id: 'MXN',
      symbol: '&#77;&#88;&#36;',
    },
    {
      name: 'Mongolia Tughrik',
      _id: 'MNT',
      symbol: '&#8366;',
    },
    {
      name: 'Mozambique Metical',
      _id: 'MZN',
      symbol: '&#77;&#84;',
    },
    {
      name: 'Namibia Dollar',
      _id: 'NAD',
      symbol: '&#78;&#36;',
    },
    {
      name: 'Nepal Rupee',
      _id: 'NPR',
      symbol: '&#8360;&#78;',
    },
    {
      name: 'Netherlands Antilles Guilder',
      _id: 'ANG',
      symbol: '&#402;',
    },
    {
      name: 'New Zealand Dollar',
      _id: 'NZD',
      symbol: '&#78;&#90;&#36;',
    },
    {
      name: 'Nicaragua Cordoba',
      _id: 'NIO',
      symbol: '&#67;&#36;',
    },
    {
      name: 'Nigeria Naira',
      _id: 'NGN',
      symbol: '&#8358;',
    },
    {
      name: 'Norway Krone',
      _id: 'NOK',
      symbol: '&#107;&#114;',
    },
    {
      name: 'Oman Rial',
      _id: 'OMR',
      symbol: '&#65020;',
    },
    {
      name: 'Pakistan Rupee',
      _id: 'PKR',
      symbol: '&#8360;',
    },
    {
      name: 'Panama Balboa',
      _id: 'PAB',
      symbol: '&#66;&#47;&#46;',
    },
    {
      name: 'Paraguay Guarani',
      _id: 'PYG',
      symbol: '&#8370;',
    },
    {
      name: 'Peru Nuevo Sol',
      _id: 'PEN',
      symbol: '&#83;&#47;&#46;',
    },
    {
      name: 'Philippines Peso',
      _id: 'PHP',
      symbol: '&#8369;',
    },
    {
      name: 'Poland Zloty',
      _id: 'PLN',
      symbol: '&#122;&#322;',
    },
    {
      name: 'Qatar Riyal',
      _id: 'QAR',
      symbol: '&#81;&#82;',
    },
    {
      name: 'Romania New Leu',
      _id: 'RON',
      symbol: '&#108;&#101;&#105;',
    },
    {
      name: 'Russia Ruble',
      _id: 'RUB',
      symbol: '&#8381;',
    },
    {
      name: 'Saint Helena Pound',
      _id: 'SHP',
      symbol: '&#163;&#83;&#72;',
    },
    {
      name: 'Saudi Arabia Riyal',
      _id: 'SAR',
      symbol: '&#83;&#82;',
    },
    {
      name: 'Serbia Dinar',
      _id: 'RSD',
      symbol: '&#1056;&#1057;&#1044;',
    },
    {
      name: 'Seychelles Rupee',
      _id: 'SCR',
      symbol: '&#83;&#82;&#101;',
    },
    {
      name: 'Singapore Dollar',
      _id: 'SGD',
      symbol: '&#83;&#36;',
    },
    {
      name: 'Solomon Islands Dollar',
      _id: 'SBD',
      symbol: '&#83;&#73;&#36;',
    },
    {
      name: 'Somalia Shilling',
      _id: 'SOS',
      symbol: '&#83;',
    },
    {
      name: 'South Africa Rand',
      _id: 'ZAR',
      symbol: '&#82;',
    },
    {
      name: 'Sri Lanka Rupee',
      _id: 'LKR',
      symbol: '&#8360;&#76;',
    },
    {
      name: 'Sweden Krona',
      _id: 'SEK',
      symbol: '&#107;&#114;',
    },
    {
      name: 'Switzerland Franc',
      _id: 'CHF',
      symbol: '&#67;&#72;&#70;',
    },
    {
      name: 'Suriname Dollar',
      _id: 'SRD',
      symbol: '&#83;&#82;&#36;',
    },
    {
      name: 'Syria Pound',
      _id: 'SYP',
      symbol: '&#163;&#83;',
    },
    {
      name: 'Taiwan New Dollar',
      _id: 'TWD',
      symbol: '&#78;&#84;&#36;',
    },
    {
      name: 'Thailand Baht',
      _id: 'THB',
      symbol: '&#3647;',
    },
    {
      name: 'Trinidad and Tobago Dollar',
      _id: 'TTD',
      symbol: '&#84;&#84;&#36;',
    },
    {
      name: 'Turkey Lira',
      _id: 'TRL',
      symbol: '&#8356;',
    },
    {
      name: 'Tuvalu Dollar',
      _id: 'TVD',
      symbol: '&#84;&#86;&#36;',
    },
    {
      name: 'Ukraine Hryvna',
      _id: 'UAH',
      symbol: '&#8372;',
    },
    {
      name: 'United Kingdom Pound',
      _id: 'GBP',
      symbol: '&#163;',
    },
    {
      name: 'United States Dollar',
      _id: 'USD',
      symbol: '&#36;',
    },
    {
      name: 'Uruguay Peso',
      _id: 'UYU',
      symbol: '&#85;&#36;',
    },
    {
      name: 'Uzbekistan Som',
      _id: 'UZS',
      symbol: '&#1089;&#1263;&#1084;',
    },
    {
      name: 'Venezuela Bolivar',
      _id: 'VEF',
      symbol: '&#66;&#115;',
    },
    {
      name: 'Viet Nam Dong',
      _id: 'VND',
      symbol: '&#8363;',
    },
    {
      name: 'Yemen Rial',
      _id: 'YER',
      symbol: '&#89;&#82;',
    },
    {
      name: 'Zimbabwe Dollar',
      _id: 'ZWD',
      symbol: '&#90;&#36;',
    },
  ],
  accounts: [
    {
      name: 'Euro Member Countries',
      _id: 'EUR',
      symbol: '&#8364;',
    },
    {
      name: 'United States Dollar',
      _id: 'USD',
      symbol: '&#36;',
    },
  ],
  cappingCurrency: [
    {
      name: 'Euro Member Countries',
      _id: 'EUR',
      symbol: '&#8364;',
    },
    {
      name: 'United States Dollar',
      _id: 'USD',
      symbol: '&#36;',
    },
  ],
};

/**
 * Truncates and fixes a received value with the exact number of decimal places indicated. Returns the final value as a string.
 *
 * @param num
 * @param decimalPlaces
 * @returns {string}
 */
export function truncateToDecimalPlaces(num, decimalPlaces) {
  const multiplier = Math.pow(10, decimalPlaces);
  const truncatedValue = Math.trunc(num * multiplier) / multiplier;
  return truncatedValue.toFixed(decimalPlaces);
}

import Currency from '@/store/constants/currency';

/**
 * This method finds a currency in a list and returns the symbol for said currency.
 *
 * @param currency
 * @returns {string}
 */
export function getCurrencySymbol(currency) {
  return Currency.list.find((item) => item._id === currency).symbol;
}

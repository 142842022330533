import { truncateToDecimalPlaces } from '@/utils/NumberUtils';
import { getCurrencySymbol } from '@/utils/ExtraUtils';

/**
 * This method will return a string to be displayed in the payout field.
 *
 * @param payout
 * @param currency
 * @returns {string}
 */
export function getPayoutField(payout, currency) {
  if (payout.model === 'BY_GROUP') return 'Multiple Payouts';
  let costs = [];
  if (payout.costs.length > 0) {
    costs = [payout.costs[0]];
  }
  let currencySymbol = getCurrencySymbol(currency);
  let payoutType;
  let payoutCost;
  switch (payout.type) {
    case 'PER_ACTION':
      payoutType = 'CPA';
      payoutCost = costs
        .map((pc) => {
          return `${currencySymbol}${pc.value}`;
        })
        .join(',');
      break;
    case 'PER_SALE':
      payoutType = 'CPS';
      payoutCost = costs
        .map((pc) => {
          return `${pc.percentage}%`;
        })
        .join(',');
      break;
    case 'PER_CLICK':
      payoutType = 'CPL';
      payoutCost = costs
        .map((pc) => {
          return `${currencySymbol}${pc.value}`;
        })
        .join(',');
      break;
    case 'PER_ACTION_AND_PER_SALE':
      payoutType = 'CPA & CPS';
      payoutCost = costs
        .map((pc) => {
          return `${currencySymbol}${pc.value} + ${pc.percentage}%`;
        })
        .join(',');
  }
  return `${payoutType} | ${payoutCost}`;
}

/**
 * This method will return a string to be displayed as the payout cost with respective symbol.
 *
 * @param payout
 * @param currency
 * @returns {string}
 */
export function getPayoutCostDisplay(payout, currency) {
  const cost = payout.costs[0];
  const truncatedCost = truncateToDecimalPlaces(cost.value, 2);
  const truncatedPercentage = truncateToDecimalPlaces(cost.percentage, 2);
  const currencySymbol = getCurrencySymbol(currency);

  switch (payout.type) {
    case 'PER_ACTION':
      return `${currencySymbol}${truncatedCost}`;
    case 'PER_SALE':
      return `${truncatedPercentage}%`;
    case 'PER_CLICK':
      return `${currencySymbol}${truncatedCost}`;
    case 'PER_ACTION_AND_PER_SALE':
      return `${currencySymbol}${truncatedCost} + ${truncatedPercentage}%`;
  }
}

<template>
  <v-container fluid class="pr-0 pl-0">
    <v-card elevation="0">
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto elevation-0">
            <v-card-actions class="ma-0 pa-0">
              <v-spacer />
              <v-btn @click="unselectItem()" class="ma-3 mt-0" depressed small>
                <v-icon>mdi-chevron-left</v-icon>
                {{ $t('commons.operation.back') }}
              </v-btn>
            </v-card-actions>
            <v-card-text>
              <v-row justify="space-between">
                <v-col cols="12" sm="2" md="3" xl="3">
                  <v-card class="v-sheet--offset mx-auto" elevation="0" max-width="266px" outlined>
                    <transition name="scale-transition">
                      <v-img
                        v-if="Offers.state.item.thumbnail"
                        :lazy-src="Offers.state.item.thumbnail"
                        :src="Offers.state.item.thumbnail"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-skeleton-loader v-else class="mx-auto" type="image"></v-skeleton-loader>
                    </transition>
                  </v-card>
                  <div class="mx-auto" align="center" justify="center">
                    <v-chip
                      class="mt-8 view-lp-btn"
                      :href="Offers.state.item.previewURL"
                      target="blank"
                      label
                      dense
                    >
                      <v-icon>mdi-eye-outline</v-icon>
                      <span class="ml-3">Landing Page</span>
                    </v-chip>
                  </div>
                  <div
                    class="mx-auto"
                    align="center"
                    justify="center"
                    v-for="(url, index) in Offers.state.item.alternativePreviewURLs"
                    :key="url"
                    hidden
                  >
                    <v-chip
                      class="mt-1"
                      align="center"
                      justify="center"
                      target="blank"
                      :href="url"
                      label
                      dense
                    >
                      <v-icon>mdi-eye-outline</v-icon>
                      <span class="ml-3">Alternative LP {{ index + 2 }}</span>
                    </v-chip>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-card class="mx-auto elevation-0" color="transparent">
                    <v-card-title class="title font-weight-light my-3 py-0">
                      {{ Offers.state.item.name }}
                    </v-card-title>
                    <v-card-text class="py-1">
                      {{ Offers.state.item.description }}
                    </v-card-text>
                    <v-card-text class="py-2">
                      <div class="flex-row">
                        <div class="pr-3">
                          <v-chip
                            small
                            label
                            :style="colorStatus(Offers.state.item.status)"
                            class="font-weight-bold"
                            >{{ Offers.state.item.status }}</v-chip
                          >
                        </div>
                        <div>
                          <span class="subheading font-weight-light">Expiration date:</span>
                          {{ Offers.state.item.expiresOn | date }}
                        </div>
                      </div>
                    </v-card-text>
                    <v-card-text
                      v-if="Offers.state.application"
                      class="title font-weight-light my-0 py-2"
                    >
                      <v-spacer />
                      <v-dialog
                        v-model="getTrackingLinkModal"
                        max-width="800px"
                        style="z-index: 9999"
                        persistent
                      >
                        <v-card>
                          <v-card-title class="text-capitalize font-weight-light justify-center">
                            {{ $t('commons.titles.TermsConditions') }}
                          </v-card-title>
                          <v-divider class="mx-5 my-3" />
                          <v-card-text>
                            <div
                              v-if="getTrackingLinkModal"
                              @scroll="onScroll"
                              class="overflow-y-auto"
                              style="max-height: 400px"
                            >
                              <div class="subtitle-1 text-decoration-underline my-3">
                                {{ $t('commons.titles.trafficSources') }}
                              </div>
                              <div>
                                <ul>
                                  <li
                                    v-for="trafficSource in Offers.state.item.trafficSources.filter(
                                      (e) => e.isAllowed
                                    )"
                                  >
                                    {{ trafficSource.name }}
                                  </li>
                                </ul>
                              </div>
                              <div class="subtitle-1 text-decoration-underline my-3">
                                {{ $t('commons.titles.targetAudience') }}
                              </div>
                              <div v-html="Offers.state.item.targetAudience" />
                              <div class="subtitle-1 text-decoration-underline my-3">
                                {{ $t('commons.titles.conversionFlow') }}
                              </div>
                              <div v-html="Offers.state.item.conversionFlow" />
                              <div class="subtitle-1 text-decoration-underline my-3">
                                {{ $t('commons.titles.notes') }}
                              </div>
                              <div v-html="Offers.state.item.termsAndConditions" />
                            </div>
                          </v-card-text>
                          <v-divider class="mx-5 my-5" />
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="red darken-1" text @click="getTrackingLinkModal = false">
                              Disagree
                            </v-btn>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="
                                Offers.actions.runApplication({}) && (getTrackingLinkModal = false)
                              "
                            >
                              Agree
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog
                        v-model="applyToRunModal"
                        max-width="800px"
                        style="z-index: 9999"
                        persistent
                      >
                        <v-card>
                          <v-card-title class="text-capitalize font-weight-light justify-center">
                            {{ $t('commons.titles.requestApproval') }}
                          </v-card-title>
                          <v-divider class="mx-5 my-3" />
                          <v-card-text>
                            <div
                              v-if="applyToRunModal"
                              @scroll="onScroll"
                              class="overflow-y-auto"
                              style="max-height: 400px"
                              ref="applyToRunDiv"
                            >
                              <div class="subtitle-1 text-decoration-underline my-3">
                                {{ $t('commons.titles.trafficSources') }}
                              </div>
                              <div>
                                <ul>
                                  <li
                                    v-for="trafficSource in Offers.state.item.trafficSources.filter(
                                      (e) => e.isAllowed
                                    )"
                                  >
                                    {{ trafficSource.name }}
                                  </li>
                                </ul>
                              </div>
                              <div class="subtitle-1 text-decoration-underline my-3">
                                {{ $t('commons.titles.targetAudience') }}
                              </div>
                              <div v-html="Offers.state.item.targetAudience" />
                              <div class="subtitle-1 text-decoration-underline my-3">
                                {{ $t('commons.titles.conversionFlow') }}
                              </div>
                              <div v-html="Offers.state.item.conversionFlow" />
                              <div class="subtitle-1 text-decoration-underline my-3">
                                {{ $t('commons.titles.notes') }}
                              </div>
                              <div v-html="Offers.state.item.termsAndConditions" />
                            </div>
                          </v-card-text>
                          <v-divider class="mx-5 my-5" />
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="red darken-1"
                              text
                              @click="(applyToRunModal = false), (termsAndConditionsRead = false)"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              :disabled="!termsAndConditionsRead"
                              color="green darken-1"
                              text
                              @click="
                                Offers.actions.runApplication({
                                  survey: Offers.state.application.survey,
                                  type: Offers.state.application.type,
                                  status: 'Apply to Run',
                                }) &&
                                  (applyToRunModal = false) &&
                                  (termsAndConditionsRead = false)
                              "
                            >
                              Agree & Request approval
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-btn
                        v-if="
                          Offers.state.application.status &&
                          Offers.state.application.type === 'approved'
                        "
                        @click.stop="
                          Offers.state.application.forceTermsAndConditions
                            ? (getTrackingLinkModal = true)
                            : Offers.actions.runApplication({ status: 'Approved' })
                        "
                        depressed
                        label
                        class="font-weight-regular"
                        color="green"
                        dark
                        block
                        >Get tracking link</v-btn
                      >
                      <v-btn
                        v-else-if="
                          Offers.state.application.status &&
                          Offers.state.application.type === 'unapproved'
                        "
                        @click.stop="applyToRunModal = true"
                        depressed
                        label
                        class="font-weight-regular"
                        color="blue lighten-1"
                        dark
                        block
                        >Apply to run</v-btn
                      >
                      <v-btn
                        v-else-if="'pending' === Offers.state.application._id"
                        depressed
                        label
                        class="font-weight-regular"
                        color="red lighten-1"
                        dark
                        block
                        >Pending approval status</v-btn
                      >
                      <v-btn
                        v-else-if="'rejected' === Offers.state.application._id"
                        depressed
                        label
                        class="font-weight-regular"
                        color="red lighten-1"
                        dark
                        block
                        >Your application to the offer has been rejected</v-btn
                      >
                      <v-window
                        v-else-if="Offers.state.application._id"
                        class="mx-auto position-relative"
                        ref="window"
                        v-model="currentTrackingLink"
                      >
                        <v-window-item v-for="(item, index) in allTrackingLinks" :key="index">
                          <v-card color="transparent" elevation="0">
                            <v-row class="fill-height">
                              <v-col cols="12">
                                <div class="d-flex">
                                  <v-card-title class="tracking-link-title ma-0 pa-0">{{
                                    item.title
                                  }}</v-card-title>
                                  <div
                                    v-if="allTrackingLinks.length > 1"
                                    class="ml-4 d-inline-flex align-center"
                                  >
                                    <v-btn
                                      class="tracking-link-arrow-btn"
                                      icon
                                      @click="$refs.window.prev()"
                                      :disabled="atStartTrackingLinks"
                                    >
                                      <v-icon small color="#00000099">mdi-chevron-left</v-icon>
                                    </v-btn>
                                    <v-btn
                                      class="tracking-link-arrow-btn"
                                      icon
                                      @click="$refs.window.next()"
                                      :disabled="atEndTrackingLinks"
                                    >
                                      <v-icon small color="#00000099">mdi-chevron-right</v-icon>
                                    </v-btn>
                                  </div>
                                </div>
                                <v-card-text class="ma-0 pa-0">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-chip
                                        v-on="on"
                                        style="max-width: 100%"
                                        depressed
                                        label
                                        class="font-weight-regular scrollable-chip"
                                        color="blue lighten-1"
                                        dark
                                        @click="
                                          copyToClipboard(
                                            `${item.link}${
                                              item.title === defaultTrackingLinkTitle
                                                ? affSubsParams
                                                : ''
                                            }`
                                          )
                                        "
                                      >
                                        <span>Copy</span>
                                        <v-icon class="mx-3">mdi-clipboard</v-icon>
                                        {{ item.link
                                        }}<span v-if="item.title === defaultTrackingLinkTitle">{{
                                          affSubsParams
                                        }}</span>
                                      </v-chip>
                                    </template>
                                    <span
                                      >{{ item.link
                                      }}<span v-if="item.title === defaultTrackingLinkTitle">{{
                                        affSubsParams
                                      }}</span></span
                                    >
                                  </v-tooltip>
                                </v-card-text>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-window-item>
                      </v-window>
                      <v-spacer />
                    </v-card-text>
                    <v-progress-linear v-else indeterminate height="33" />
                    <v-card-text>
                      <div class="flex-row pb-2">
                        <div class="pr-3 subheading font-weight-light">Payout:</div>
                        <div class="flex-row flex-wrap" style="gap: 8px">
                          <div>
                            <v-chip
                              style="height: 28px"
                              v-if="Offers.state.item.payout.model !== 'BY_GROUP'"
                              class="white--text"
                              color="blue"
                              label
                              small
                              v-html="
                                getPayoutField(Offers.state.item.payout, Offers.state.item.currency)
                              "
                            >
                            </v-chip>
                            <v-btn
                              v-else
                              @click="openPanel(0)"
                              class="white--text"
                              color="blue"
                              label
                              small
                            >
                              Show Multiple Payouts
                            </v-btn>
                          </div>
                          <div>
                            <v-btn
                              v-if="Offers.state.item.hasEvents"
                              @click="
                                openPanel(Offers.state.item.payout.model === 'BY_GROUP' ? 3 : 2)
                              "
                              class="white--text"
                              color="blue"
                              label
                              small
                            >
                              Show Events
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <v-divider />
                      <div class="flex-row py-2">
                        <div class="pr-3 subheading font-weight-light">Countries:</div>
                        <div class="flex-row flex-wrap">
                          <span
                            v-if="
                              !Offers.state.item._country || Offers.state.item._country.length === 0
                            "
                            >ALL</span
                          >
                          <span
                            v-for="(country, index) in sortedTargetedCountries"
                            :key="index"
                            :title="Countries[country]"
                          >
                            <span>{{ country }}</span
                            ><span v-if="index !== sortedTargetedCountries.length - 1"
                              >,&nbsp;</span
                            >
                          </span>
                        </div>
                      </div>
                      <v-divider />
                      <div class="py-2 restrictions-container">
                        <div class="pr-3 subheading font-weight-light align-self-start">
                          Restrictions:
                        </div>
                        <div class="restrictions">
                          <v-row class="pt-2">
                            <v-col
                              cols="6"
                              v-for="(source, index) in restrictedTrafficSources"
                              class="pt-1 pb-0"
                              ><v-icon color="red" small>{{ Icons.close }}</v-icon
                              ><span>{{ source.name }}</span></v-col
                            >
                          </v-row>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-card class="mx-auto elevation-0" color="transparent">
                    <v-row class="ma-3 mr-5">
                      <v-chip
                        depressed
                        label
                        class="font-weight-regular"
                        color="primary"
                        style="max-width: 282px"
                      >
                        <span class="chip-overflow">Offer ID: {{ Offers.state.item._id }}</span>
                      </v-chip>
                    </v-row>
                    <v-row class="ma-3 mr-5 mt-5">
                      <v-col
                        class="pa-0"
                        v-if="
                          Offers.state.application &&
                          Offers.state.application.applicationStatus === 'running'
                        "
                      >
                        <AffSub @input="affSubsParams = $event" />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-row justify="space-between">
                <v-col cols="12" md="6">
                  <v-card class="ma-2" min-height="100%">
                    <v-app-bar dense flat color="#e0e0e0" height="30px">
                      <v-toolbar-title class="app-bar-title">More Info</v-toolbar-title>
                    </v-app-bar>
                    <v-card-text class="pb-0">
                      <div class="flex-row py-1">
                        <div class="pr-3 subheading font-weight-light col-min-width">
                          Device Targeting:
                        </div>
                        <div
                          v-if="
                            !Offers.state.item.deviceType ||
                            Offers.state.item.deviceType.length === 0
                          "
                        >
                          <span>ALL</span>
                        </div>
                        <div v-else>
                          <span
                            v-for="(device, index) in Offers.state.item.deviceType"
                            :key="index"
                          >
                            <span>{{ device.charAt(0).toUpperCase() + device.slice(1) }}</span
                            ><span v-if="index !== Offers.state.item.deviceType.length - 1"
                              >,&nbsp;
                            </span>
                          </span>
                        </div>
                      </div>
                      <v-divider />
                      <div class="flex-row py-1">
                        <div class="pr-3 subheading font-weight-light col-min-width">
                          Target Audience:
                        </div>
                        <div v-if="!!Offers.state.item.targetAudience">
                          {{ Offers.state.item.targetAudience }}
                        </div>
                        <div v-else>N/A</div>
                      </div>
                      <v-divider />
                      <div class="flex-row py-1">
                        <div class="pr-3 subheading font-weight-light col-min-width">
                          Allowed Traffic Sources:
                        </div>
                        <div>{{ allowedTrafficSourcesStringList }}</div>
                      </div>
                      <v-divider />
                      <div class="flex-row py-1">
                        <div class="pr-3 subheading font-weight-light col-min-width">
                          Verticals:
                        </div>
                        <div v-if="!!Offers.state.item._categories.length">
                          {{ verticalsDisplay(Offers.state.item._categories) }}
                        </div>
                        <div v-else>N/A</div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card class="ma-2" min-height="100%">
                    <v-app-bar dense flat color="#e0e0e0" height="30px">
                      <v-toolbar-title class="app-bar-title">Conversion Flow</v-toolbar-title>
                    </v-app-bar>
                    <v-card-text class="pb-0">
                      <div v-if="!!Offers.state.item.conversionFlow" class="flex-row py-1">
                        {{ Offers.state.item.conversionFlow }}
                      </div>
                      <div v-else>N/A</div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="mt-3">
              <v-expansion-panels v-model="panel" popout outlined>
                <v-expansion-panel v-if="Offers.state.item.payout.model === 'BY_GROUP'">
                  <v-expansion-panel-header
                    class="text-capitalize text-left font-weight-light"
                    expand-icon="mdi-cursor-pointer"
                    >Multiple Payouts</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-card flat tile>
                      <v-data-table
                        v-bind:headers="payoutsHeaders"
                        v-bind:items="multiplePayouts"
                        v-bind:no-data-text="$t('commons.table.noDataText')"
                        v-bind:no-results-text="$t('commons.table.noResultsText')"
                        v-bind:hide-default-footer="true"
                        disable-pagination
                      >
                        <template v-slot:item._country="{ item }">
                          <span v-for="(country, index) in item._country" :key="index">
                            <span v-if="index > 0">, </span>
                            <span :title="Countries[country]">{{ country }}</span>
                          </span>
                        </template>
                        <template v-slot:item.deviceType="{ item }">
                          {{ item.deviceType.join(', ') }}
                        </template>
                        <template v-slot:item.deviceOs="{ item }">
                          {{ item.deviceOs.join(', ') }}
                        </template>
                        <template v-slot:item.costValue="{ item }">
                          <span v-html="currencySymbol"></span>
                          <span>{{ item.costValue }}</span>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="text-capitalize text-left font-weight-light"
                    expand-icon="mdi-cursor-pointer"
                    >{{ $t('commons.titles.notes') }}</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-card flat tile>
                      <v-card-title class="text-capitalize font-weight-light">
                        <v-spacer />
                        {{ $t('commons.titles.notes') }}
                        <v-spacer />
                      </v-card-title>
                      <v-divider class="mx-10" />
                      <v-card-text v-html="Offers.state.item.termsAndConditions" />
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="text-capitalize text-left font-weight-light"
                    expand-icon="mdi-cursor-pointer"
                    >{{ $t('commons.titles.capping') }}</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-card flat tile>
                      <v-card-title class="text-capitalize font-weight-light">
                        <v-spacer />
                        {{ $t('commons.titles.capping') }}
                        <v-spacer />
                      </v-card-title>
                      <v-divider class="mx-10" />
                      <v-card-text>
                        <Capping :offer="Offers.state.item" />
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="Offers.state.item.hasEvents">
                  <v-expansion-panel-header
                    class="text-capitalize text-left font-weight-light"
                    expand-icon="mdi-cursor-pointer"
                    >{{ $t('commons.titles.events') }}</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-card flat tile>
                      <v-data-table
                        class="events-table"
                        v-bind:headers="eventsHeaders"
                        v-bind:items="Offers.state.item.events"
                        v-bind:no-data-text="$t('commons.table.noDataText')"
                        v-bind:no-results-text="$t('commons.table.noResultsText')"
                        v-bind:hide-default-footer="true"
                        disable-pagination
                      >
                        <template v-slot:item.name="{ item }">
                          <span>{{ item.name }}</span>
                        </template>
                        <template v-slot:item.payout="{ item }">
                          <span
                            v-html="getPayoutCostDisplay(item.payout, Offers.state.item.currency)"
                          ></span>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="text-capitalize text-left font-weight-light"
                    expand-icon="mdi-cursor-pointer"
                    >Third Party Tracking</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-card flat tile>
                      <v-divider class="mx-10" />
                      <v-card-text>
                        <AffiliatePixels
                          :offer="Offers.state.item"
                          :viewFilters="false"
                          :viewTitle="false"
                          :viewEdit="false"
                        />
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="text-capitalize text-left font-weight-light"
                    expand-icon="mdi-cursor-pointer"
                    >Creatives</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-card flat tile>
                      <v-card-title class="text-capitalize font-weight-light">
                        <v-spacer />
                        {{ $t('commons.titles.creatives') }}
                        <v-spacer />
                      </v-card-title>
                      <v-divider class="mx-10" />
                      <creatives :_offer="Offers.state.item._id" />
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
            <v-card-actions class="ma-0 pa-0">
              <v-spacer />
              <v-btn @click="unselectItem()" class="ma-3 mb-0" depressed small>
                <v-icon>mdi-chevron-left</v-icon>
                {{ $t('commons.operation.back') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { inject, computed, reactive, ref, toRef } from '@vue/composition-api';
import { getPayoutCostDisplay, getPayoutField } from '@/utils/PayoutUtils';
import { getCurrencySymbol } from '@/utils/ExtraUtils';
import _ from 'lodash';
import Capping from './Capping.vue';
import Creatives from './Creatives';
import AffiliatePixels from '../AffiliatePixels';
import Countries from '../../../store/constants/Countries';
import AffSub from '../../Components/AffSub.vue';

export default {
  name: 'offer-preview',
  components: {
    Capping,
    creatives: Creatives,
    AffiliatePixels,
    AffSub,
  },
  data() {
    return {
      panel: null,
    };
  },
  methods: {
    getPayoutCostDisplay,
    getPayoutField,
    colorStatus(status) {
      let colorStat = status === 'ACTIVE' ? 'backgroundColor:#c0f3b6; color:#009600;' : '';
      return colorStat;
    },
    openPanel(index) {
      this.panel = index;
    },
    onScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      // Add a small buffer (e.g., 1px) to account for rounding differences
      const bufferOnePixel = 1;
      const bottomReached = scrollTop + clientHeight >= scrollHeight - bufferOnePixel;
      if (bottomReached) {
        this.termsAndConditionsRead = true;
      }
    },
    checkContentHasScroll(ref) {
      if (ref) {
        setTimeout(() => {
          if (ref.scrollHeight <= ref.clientHeight) {
            this.termsAndConditionsRead = true;
          }
        }, 100);
      }
    },
  },
  setup(props, context) {
    const Offers = inject('Offers');
    const Categories = inject('Categories');
    const window = ref(null);
    const unselectItem = () => {
      Offers.actions.unselectItem();
      context.emit('setPreSelectItemPage');
    };
    const verticalsDisplay = (categoryIds) => {
      const categories = Categories.state.items;
      let arrayCategories = [];
      if (!!categoryIds.length) {
        categoryIds.forEach((categoryId) => {
          let validCat = categories.find((cat) => cat._id === categoryId);
          if (validCat) {
            arrayCategories.push(validCat.name);
          }
        });
      }
      return arrayCategories.join(' , ');
    };
    const self = reactive({
      search: '',
      getTrackingLinkModal: false,
      applyToRunModal: false,
      termsAndConditionsRead: false,
      multiplePayouts: computed(() => {
        const payouts = [];
        if (Offers.state.item.payout.costs[0].value > 0) {
          payouts.push({
            _country: ['Default'],
            deviceType: Offers.state.item.deviceType,
            deviceOs: Offers.state.item.deviceOs,
            costs: Offers.state.item.payout.costs,
          });
        }
        payouts.push.apply(payouts, Offers.state.item.payout.group);
        payouts.forEach((e) => {
          e._country = e._country.length > 0 ? e._country : ['All'];
          e.deviceType = e.deviceType.length > 0 ? e.deviceType : ['All'];
          e.deviceOs = e.deviceOs.length > 0 ? e.deviceOs : ['All'];
          e.costValue = e.costs[0].value;
        });
        return payouts;
      }),
      currencySymbol: computed(() => {
        return getCurrencySymbol(Offers.state.item.currency);
      }),
      allowedTrafficSourcesStringList: computed(() => {
        const allowed = _.cloneDeep(Offers.state.item.trafficSources).filter(
          (source) => !!source.isAllowed
        );
        return !Offers.state.item.trafficSources.length || !allowed.length
          ? 'N/A'
          : allowed.map((source) => source.name).join(', ');
      }),
      restrictedTrafficSources: computed(() => {
        return _.cloneDeep(Offers.state.item.trafficSources).filter((source) => !source.isAllowed);
      }),
      sortedTargetedCountries: computed(() => {
        return _.cloneDeep(Offers.state.item._country).sort((a, b) => a.localeCompare(b));
      }),
      affSubsParams: '',
      currentTrackingLink: 0,
    });
    const defaultTrackingLinkTitle = 'Default tracking link';
    const allTrackingLinks = computed(() => {
      const baseLink = `https://affcpatrk.com/track?id=${Offers.state.application._id}`;
      const links = [
        {
          title: defaultTrackingLinkTitle,
          link: baseLink,
        },
      ];

      if (!!Offers.state.item.alternativeUrls.length) {
        Offers.state.item.alternativeUrls.forEach((_, index) => {
          links.push({
            title: `Tracking link LP ${index + 1}`,
            link: `${baseLink}&lp=${index + 1}`,
          });
        });
        links.push({
          title: 'Rotative tracking link',
          link: `${baseLink}&lp=rotate`,
        });
      }

      return links;
    });
    const atStartTrackingLinks = computed(() => {
      return self.currentTrackingLink === 0;
    });
    const atEndTrackingLinks = computed(() => {
      return (
        !!allTrackingLinks.value.length &&
        self.currentTrackingLink === allTrackingLinks.value.length - 1
      );
    });
    const payoutsHeaders = [
      {
        text: 'Country',
        align: 'start',
        value: '_country',
        width: 150,
      },
      {
        text: 'Device',
        align: 'start',
        value: 'deviceType',
        width: 350,
      },
      {
        text: 'OS',
        align: 'start',
        value: 'deviceOs',
        width: 100,
      },
      {
        text: 'Payout',
        align: 'start',
        value: 'costValue',
        width: 100,
      },
    ];
    const eventsHeaders = [
      {
        text: 'Event Name',
        align: 'start',
        value: 'name',
        width: 300,
      },
      {
        text: 'Payout',
        align: 'start',
        value: 'payout',
        width: 100,
      },
    ];
    const copyToClipboard = (text) => {
      if (navigator.clipboard) navigator.clipboard.writeText(text);
    };
    return {
      Offers,
      ...self,
      copyToClipboard,
      unselectItem,
      Countries,
      verticalsDisplay,
      payoutsHeaders,
      eventsHeaders,
      window,
      defaultTrackingLinkTitle,
      allTrackingLinks,
      atStartTrackingLinks,
      atEndTrackingLinks,
      currentTrackingLink: toRef(self, 'currentTrackingLink'),
    };
  },
  watch: {
    applyToRunModal() {
      if (this.applyToRunModal) {
        this.$nextTick(() => {
          this.checkContentHasScroll(this.$refs.applyToRunDiv);
        });
      } else {
        this.termsAndConditionsRead = false;
      }
    },
  },
};
</script>
<style lang="scss">
.scrollable-chip {
  overflow-x: auto;
}
.scrollable-chip::-webkit-scrollbar {
  height: 5px;
}
.scrollable-chip::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}
.chip-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flex-row {
  display: flex;
  align-items: center;
}
.events-table {
  margin: 0 auto;
  max-width: 70%;

  @media screen and (max-width: 800px) {
    max-width: 100%;
  }
}
.app-bar-title {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.9rem;
}
.col-min-width {
  min-width: 175px;
}
.position-relative {
  position: relative;
}
.tracking-link-title {
  font-size: 1rem;
  min-width: 155px;
}
.restrictions-container {
  display: flex;
  flex-direction: row;
}
.tracking-link-arrow-btn {
  height: 20px !important;
  width: 20px !important;
  border-radius: 50%;
  background-color: #e0e0e0;
  margin-right: 15px;
}
@media (min-width: 600px) and (max-width: 959px) {
  .view-lp-btn {
    font-size: 0.5rem !important;
    padding: 0 5px !important;
    height: 25px !important;
  }
  .v-chip.view-lp-btn .v-chip__content .v-icon {
    font-size: 0.9rem !important;
  }
  .v-chip.view-lp-btn .v-chip__content span {
    margin-left: 2px !important;
  }
  .restrictions-container {
    flex-direction: column;

    .restrictions {
      margin-top: 3px;
    }
  }
}
</style>

<template>
  <div>
    <v-row class="mt-1">
      <v-col>
        <v-row class="d-flex align-center justify-center">
          <v-col style="max-width: 200px"> Conversion capping limit: </v-col>
          <v-col>
            <v-chip small label v-if="offer.conversionCapping">
              {{ formatConversionCappingLimit(offer.conversionCappingLimit) }}
            </v-chip>
            <v-chip small label v-else>OPEN CAP</v-chip>
          </v-col>
          <template v-if="offer.conversionCapping">
            <v-col style="max-width: 200px"> Conversion capping period: </v-col>
            <v-col>
              <v-chip small label>
                {{ offer.conversionCappingPeriod }}
              </v-chip>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  setup() {
    const formatConversionCappingLimit = (value) => {
      return Math.floor(value);
    };
    return { formatConversionCappingLimit };
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
};
</script>

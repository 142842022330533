<template>
  <div id="totals" :class="title">
    <h4 class="ma-2">TOTALS</h4>
    <hr />
    <v-flex v-for="(calc, index) in calculations" :key="index" class="colCalc" :class="column">
      <v-col>
        <div>{{ calc.name }}</div>
        <div>
          {{ symbols(calc.value) }} {{ formatNumber(calc.value, items[calc.value])
          }}{{ percentage(calc.value) }}
        </div>
      </v-col>
      <v-spacer />
    </v-flex>
  </div>
</template>
<script>
import numeral from 'numeral';
import { inject } from '@vue/composition-api';

export default {
  name: 'calculation-totals',
  setup(props, context) {
    const Reports = inject('ReportTS');
    const affiliateCurrency = Reports.actions.affiliateCurrency();
    return {
      affiliateCurrency,
    };
  },
  props: {
    items: {
      type: Object,
      default: () => {},
    },
    calculations: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    column() {
      let count = 0;
      if (this.calculations) {
        count = this.calculations.length;
      }
      this.calculations.find((calc) => {
        if (calc.value === 'payout') {
          calc.value = 'payout' + this.affiliateCurrency;
          return true;
        }
      });
      let styleCol =
        count == 1
          ? 'col1'
          : count == 2
          ? 'col2'
          : count == 3
          ? 'col3'
          : count == 4
          ? 'col4'
          : count == 5
          ? 'col5'
          : 'col6';
      return styleCol;
    },
    title() {
      let count = 0;
      if (this.calculations) {
        count = this.calculations.length;
      }
      let styleTotal = count >= 1 ? 'view' : 'notView';
      return styleTotal;
    },
  },
  methods: {
    formatNumber(val, num) {
      if (val === 'transactions' || val === 'conversions' || val === 'uniqueTransaction') {
        return this.integerFormat(num);
      } else if (
        val === 'payout' ||
        val === 'payoutUSD' ||
        val === 'payoutEUR' ||
        val === 'revenue' ||
        val === 'revenueUSD' ||
        val === 'revenueEUR'
      ) {
        return this.floatFormatCommons(num);
      } else {
        return this.floatFormatReports(num);
      }
    },
    floatFormatReports(value) {
      if (!value) {
        value = 0;
      }
      return numeral(value).format('0,0.000');
    },
    floatFormatCommons(value) {
      if (!value) {
        value = 0;
      }
      return numeral(value).format('0,0.00');
    },
    integerFormat(value) {
      if (!value) {
        value = 0;
      }
      return numeral(value).format('0,0');
    },
    symbols(val) {
      if (!val) return '';

      const currencySymbol =
        this.affiliateCurrency === 'USD' ? '$' : this.affiliateCurrency === 'EUR' ? '€' : '';

      const fieldCurrency = val.slice(-3);
      const baseField = val.slice(0, -3);

      if (
        ['e', 'epc', 'payout', 'ppc', 'revenue'].includes(baseField) &&
        (fieldCurrency === 'USD' || fieldCurrency === 'EUR')
      ) {
        return currencySymbol;
      }

      return '';
    },
    percentage(val) {
      if (val === 'cr') {
        return '%';
      }
    },
  },
};
</script>
<style lang="scss">
.valTotal {
  font-size: 17px;
  color: #1f8bc4;
  margin-bottom: 5px;
}
hr {
  border: none;
  border-top: 1px solid lightgray;
}
#totals {
  margin-bottom: 20px;
  table-layout: fixed;
  max-width: 100%;
  &.view {
    display: block;
  }
  &.notView {
    display: none;
  }
  .v-data-table {
    margin-top: 10px;
    thead {
      th {
        text-align: left !important;
        background: white;
        border-bottom: none;
        height: 30px !important;
        span {
          text-transform: uppercase;
          font-size: 13px;
          text-align: left !important;
        }
      }
    }
    tbody {
      tr {
        background: white;
        td {
          color: #42a5f5;
          font-size: 16px;
          height: 25px;
          font-weight: bold;
          text-align: left !important;
          padding-bottom: 20px;
        }
      }
    }
  }
}
.colCalc {
  display: inline-block;
  flex-basis: fit-content;
  .col {
    div {
      font-size: 17px;
      color: #1f8bc4;
      font-weight: bold;
      &:first-child {
        color: grey;
        text-transform: uppercase;
        font-size: 13px;
      }
    }
  }
  &.col1 {
    width: 100%;
  }
  &.col2 {
    width: calc(100% / 3);
  }
  &.col3 {
    width: calc(100% / 3);
  }
  &.col4 {
    width: calc(100% / 4);
  }
  &.col5 {
    width: calc(100% / 5);
  }
  &.col6 {
    width: calc(100% / 6);
  }
}
</style>

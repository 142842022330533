<template>
  <v-container fluid class="pr-0 pl-0">
    <v-dialog v-model="Conversions.state.csvHeadersModal" width="1200" persistent>
      <v-card>
        <v-card-title class="py-2 mb-3 text-subtitle-1 font-weight-light"
          >Select Headers to be included in the exported file:</v-card-title
        >
        <v-card-text>
          <v-row dense>
            <v-col v-for="(column, index) in csvHeadersOptions" :key="index">
              <v-checkbox
                v-for="(header, headerIndex) in column"
                :key="headerIndex"
                dense
                :label="header"
                :value="header"
                v-model="Conversions.state.csvHeaders"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="mt-5 mx-5">
            <v-col v-if="isCsvHeadersOkDisabled">
              <span style="color: red" class="subtitle-2">
                Please select at least one header from the list.
              </span>
            </v-col>
            <v-col class="text-right">
              <v-btn @click="Conversions.actions.hideCsvHeadersModal()" min-width="100" class="mr-4"
                >Cancel</v-btn
              >
              <v-btn
                @click="exportConversion()"
                min-width="100"
                color="primary"
                :disabled="isCsvHeadersOkDisabled"
                >OK</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card elevation="0">
      <v-toolbar flat>
        <h2 class="heading font-weight-normal grey--text">{{ $t('conversions.title') }}</h2>
      </v-toolbar>
      <v-col xs="12" md="12">
        <v-row class="pt-0 mt-0">
          <v-col cols="12">
            <v-form v-model="filtersForm_valid" ref="filtersForm" lazy-validation>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <DateRange
                    ref="dateRange"
                    :value="Conversions.state.filters.dateRange"
                    @input="Conversions.actions.applyFilter({ key: 'dateRange', value: $event })"
                    style="z-index: 1"
                  />
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-card outlined flat class="pl-3 pr-3 pb-3">
                    <v-card-title>
                      <span class="subtitle-1 font-weight-light text--secondary">{{
                        $t('conversions.general')
                      }}</span>
                    </v-card-title>
                    <span class="subtitle-2 font-weight-light">{{
                      $t('transactions.conversionId')
                    }}</span>
                    <v-text-field
                      :rules="[
                        (v) =>
                          isValidObjectId(v) ||
                          v === null ||
                          v === undefined ||
                          v === '' ||
                          $t('commons.rules.errorInvalidId'),
                      ]"
                      hide-details="auto"
                      :value="Conversions.state.selectedFilters._id"
                      v-bind:label="$t('transactions.identifier')"
                      @input="Conversions.actions.applyFilter({ key: '_id', value: $event })"
                      dense
                      solo-inverted
                      flat
                      single-line
                      clearable
                      :prepend-inner-icon="Icons._id"
                    />
                    <span class="subtitle-2 font-weight-light">{{
                      $t('transactions.status')
                    }}</span>
                    <v-select
                      :value="Conversions.state.selectedFilters.status"
                      @input="Conversions.actions.applyFilter({ key: 'status', value: $event })"
                      :items="['APPROVED', 'DISAPPROVED', 'PENDING']"
                      v-bind:label="$t('transactions.status')"
                      clearable
                      solo-inverted
                      flat
                      dense
                      small-chips
                      hide-details
                      prepend-inner-icon="mdi-cursor-pointer"
                    >
                      <template v-slot:item="data">
                        <v-chip
                          small
                          label
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          class="ma-1"
                        >
                          {{ data.item | upperCase | capitalize }}
                        </v-chip>
                      </template>
                      <template v-slot:selection="data">
                        <v-chip
                          small
                          label
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          class="ma-1"
                        >
                          {{ data.item | upperCase | capitalize }}
                        </v-chip>
                      </template>
                    </v-select>

                    <span class="subtitle-2 font-weight-light">{{ $t('transactions.offer') }}</span>
                    <custom-autocomplete
                      :entity="ENTITIES.OFFER"
                      :value="Conversions.state.selectedFilters._offer"
                      @input="Conversions.actions.applyFilter({ key: '_offer', value: $event })"
                      @removeChip="removeFilter($event, '_offer')"
                      v-bind:label="$t('transactions.offer')"
                      :customFilters="{ status: 'ACTIVE,TEST' }"
                      :prepend-inner-icon="Icons.offer"
                      dense
                      flat
                      solo-inverted
                      clearable
                      chips
                      multiple
                      clearableChips
                      hide-details
                      solo
                    />
                    <span class="subtitle-2 font-weight-light">Country</span>
                    <custom-autocomplete
                      :entity="ENTITIES.COUNTRY"
                      :value="Conversions.state.selectedFilters._country"
                      @input="Conversions.actions.applyFilter({ key: '_country', value: $event })"
                      @removeChip="removeFilter($event, '_country')"
                      item-text="name"
                      item-value="code"
                      label="Country"
                      prepend-inner-icon="mdi-cursor-pointer"
                      dense
                      flat
                      solo-inverted
                      clearable
                      chips
                      multiple
                      clearableChips
                      hide-details
                      solo
                    />

                    <span class="subtitle-2 font-weight-light">{{ $t('transactions.event') }}</span>
                    <v-text-field
                      hide-details="auto"
                      :value="Conversions.state.selectedFilters.event"
                      v-bind:label="$t('transactions.event')"
                      @input="Conversions.actions.applyFilter({ key: 'event', value: $event })"
                      dense
                      solo-inverted
                      flat
                      single-line
                      clearable
                      :prepend-inner-icon="Icons.event"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12">
            <v-card-actions class="pa-5">
              <v-spacer />
              <v-btn
                @click="loadItems()"
                :loading="Conversions.state.loading"
                :disabled="Conversions.state.showExportingReport"
                class="ml-6"
                small
                depressed
              >
                <v-icon class="mr-1">{{ Icons.send }}</v-icon>
                <span class="text-capitalize">Run Report</span>
              </v-btn>
              <v-btn
                @click="Conversions.actions.showCsvHeadersModal(), resetCsvHeaders()"
                v-if="Conversions.state.showExportButton"
                small
                depressed
                class="ml-6"
                :disabled="Conversions.state.disableExportButton"
              >
                <span class="material-icons downIcon">download</span>
                <span v-if="Conversions.state.showExportReport" class="text-capitalize"
                  >Export Report</span
                >
                <div class="customLoadingWithText" v-if="Conversions.state.showExportingReport">
                  <v-progress-circular
                    indeterminate
                    size="20"
                    color="$vuetify.theme.dark ? 'white' : 'black'"
                  ></v-progress-circular>
                  <span class="text-capitalize exporting-report">Exporting Report</span>
                </div>
              </v-btn>
              <v-btn
                @click="Conversions.actions.downloadButtonHandler()"
                small
                depressed
                class="ml-6"
                v-else-if="Conversions.state.showDownloadButton"
                :href="Conversions.state.csvUrl"
                target="_blank"
              >
                <span class="material-icons downIcon">download</span>
                <span v-if="Conversions.state.doneCsv" class="text-capitalize"
                  >Download Report</span
                >
              </v-btn>
              <v-btn
                small
                style="width: 110px"
                :class="'disable-events'"
                depressed
                class="ml-6"
                color="red"
                v-else
              >
                <span class="material-icons downIcon">download</span>
                <span v-if="Conversions.state.doneCsv" class="text-capitalize"> ERROR </span>
              </v-btn>
            </v-card-actions>
            <CalculationTotals
              :items="Conversions.state.totals"
              :calculations="conversionsType(Conversions.state.calculations)"
              v-if="!!Object.keys(Conversions.state.totals).length && !Conversions.state.loading"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-card flat tile class="pr-3 pl-3">
        <v-data-table
          v-bind:headers="Conversions.state.headers"
          v-bind:items="Conversions.state.items"
          v-bind:loading="Conversions.state.loading"
          v-bind:no-data-text="$t('commons.table.noDataText')"
          v-bind:no-results-text="$t('commons.table.noResultsText')"
          single-expand
          item-key="_id"
          :options.sync="Conversions.state.pagination"
          :server-items-length="Conversions.state.total"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [20, 30, 40, 50],
          }"
          must-sort
          @update:options="paginationHandler()"
        >
          <v-tooltip top> </v-tooltip>
          <template v-slot:item._id="{ item }">
            <v-chip
              label
              small
              transparent
              class="font-weight-regular scrollable-chip"
              @click="copyToClipboard(`${item._id}`)"
              ><v-icon class="copy">{{ Icons.copyContent }}</v-icon>
              <FormattedText :value="item._id" :num="10" class="transparent"
            /></v-chip>
          </template>
          <template v-slot:item._offer="{ item }">
            <v-chip
              label
              small
              transparent
              class="font-weight-regular scrollable-chip"
              @click="copyToClipboard(`${item._offer}`)"
              ><v-icon class="copy">{{ Icons.copyContent }}</v-icon>
              <FormattedText :value="item._offer" :num="10" class="transparent"
            /></v-chip>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip label small>{{ item.status | upperCase | capitalize }}</v-chip>
          </template>
          <template v-slot:item.createdAt="{ item }">
            <v-chip label small>{{ item.createdAt | dateTime }}</v-chip>
          </template>
          <template v-slot:item.event="{ item }">
            {{ item.event ? item.event : 'Default' }}
          </template>
          <template v-slot:item.offerName="{ item }">
            <v-chip
              label
              small
              transparent
              class="font-weight-regular scrollable-chip"
              @click="copyToClipboard(`${item.offerName}`)"
              ><v-icon class="copy">{{ Icons.copyContent }}</v-icon>
              <FormattedText :value="item.offerName" :num="30" class="transparent"
            /></v-chip>
          </template>
          <template v-slot:item.countryName="{ item }">
            <v-chip label small> {{ item.countryName }} </v-chip>
          </template>
          <template v-slot:item.affiliate="{ item }">
            {{ item.affiliateName }}
          </template>

          <template v-slot:item.advertiser="{ item }">
            {{ item.advertiserName }}
          </template>
          <template v-slot:item.revenue="{ item }">
            <v-chip label small color="blue lighten-1" dark
              >{{ item.revenue }} {{ item.currency }}</v-chip
            >
          </template>
          <template v-slot:item.payoutEUR="{ item }">
            <v-chip label small color="blue lighten-1" dark
              >{{ '€' }}{{ item.payoutEUR | floatFormatCommons }}</v-chip
            >
          </template>
          <template v-slot:item.payoutUSD="{ item }">
            <v-chip label small color="blue lighten-1" dark
              >{{ '$' }}{{ item.payoutUSD | floatFormatCommons }}</v-chip
            >
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </v-container>
</template>
<script>
import {
  inject,
  onMounted,
  reactive,
  watch,
  computed,
  onActivated,
  onUnmounted,
  ref,
} from '@vue/composition-api';
import DataTable from '../Components/DataTable';
import DateRange from '../Components/DateRange';
import CalculationTotals from '../Components/CalculationTotals.vue';
import PccContainerProperties from '../Components/PccContainerProperties';
import PccButtonShowHide from '../Components/PccButtonShowHide';
import FormattedText from '../Components/FormattedText';
import { isValidObjectId } from '@/store/constants/Validations';
import CustomAutocomplete from '../Components/CustomAutocomplete';
import ENTITIES from '../../store/constants/Entities';

export default {
  name: 'conversions',
  components: {
    'pcc-container-properties': PccContainerProperties,
    'pcc-button-show-hide': PccButtonShowHide,
    DataTable,
    DateRange,
    CalculationTotals,
    FormattedText,
    CustomAutocomplete,
    // LocationsConversion,
  },
  data: () => {
    return {
      show: false,
      filtersForm_valid: true,
      ENTITIES,
    };
  },
  setup(props, context) {
    const Application = inject('Application');
    const Conversions = inject('Conversions');
    const dateRange = ref(null);
    const filtersForm = ref(null);
    const form = ref(null);

    onMounted(async () => {
      await Conversions.actions.loadFilterItems();
    });
    onUnmounted(async () => {
      Conversions.actions.resetFilters();
    });
    function loadItems() {
      if (filtersForm.value.validate()) {
        dateRange.value.setMaxDateOnNull();
        Conversions.actions.loadItems();
      }
    }
    function conversionsType(conversions) {
      const validValues = ['payoutUSD', 'payoutEUR', 'conversions'];
      return conversions.filter((conversion) => validValues.includes(conversion.value));
    }
    function exportConversion() {
      Conversions.actions.hideCsvHeadersModal();
      if (filtersForm.value.validate()) {
        dateRange.value.setMaxDateOnNull();
        Conversions.actions.exportCsv();
      }
    }

    function removeFilter(item, type) {
      const index =
        type === '_country'
          ? Conversions.state.filters[type].indexOf(item.code)
          : Conversions.state.filters[type].indexOf(item._id);
      if (index >= 0) {
        Conversions.state.filters[type].splice(index, 1);
        if (!Conversions.state.filters[type].length) {
          delete Conversions.state.filters[type];
        }
      }
    }

    function customFilter(item, queryText) {
      return (
        item._id.toLowerCase().includes(queryText.toLowerCase()) ||
        item.name.toLowerCase().includes(queryText.toLowerCase())
      );
    }

    function paginationHandler() {
      if (!Conversions.state.initialStatePagination) {
        dateRange.value.setMaxDateOnNull();
        Conversions.actions.loadItems();
      }
    }

    const isCsvHeadersOkDisabled = computed(() => !Conversions.state.csvHeaders.length);
    const currency = Application.state.user.currency;
    function resetCsvHeaders() {
      Conversions.actions.setCsvHeaders([
        'Day',
        'Hour',
        'Conversion ID',
        'Status',
        'Event',
        'Offer',
        'Offer ID',
        'Smartlink Name',
        'Smartlink ID',
        'Country',
        `Payout ${currency}`,
      ]);
    }
    const csvHeadersOptions = [
      ['Day', 'Hour', 'Conversion ID'],
      ['Status', 'Event', `Payout ${currency}`],
      ['Offer', 'Offer ID', 'Smartlink Name'],
      ['Smartlink ID', 'Country', 'Aff_sub'],
      ['Aff_sub1', 'Aff_sub2', 'Aff_sub3'],
      ['Aff_sub4', 'Aff_sub5'],
    ];
    const copyToClipboard = (text) => {
      if (navigator.clipboard) navigator.clipboard.writeText(text);
    };

    return {
      Conversions,
      csvHeadersOptions,
      isCsvHeadersOkDisabled,
      isValidObjectId,
      filtersForm,
      conversionsType,
      exportConversion,
      removeFilter,
      customFilter,
      loadItems,
      paginationHandler,
      resetCsvHeaders,
      dateRange,
      copyToClipboard,
    };
  },
};
</script>

<template>
  <v-container fluid class="elevation-0 pt-6">
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" class="pl-0 pr-0">
        <v-card class="mx-auto elevation-0">
          <v-row justify="space-between">
            <v-col cols="12" sm="4" md="3" xl="2" class="pl-5">
              <cropper-component
                :url="application.state.user.thumbnail"
                :url_default="require('@/assets/thumbnail.jpg')"
                @input="changeThumbnail($event)"
                label="Thumbnail"
                class="mx-auto"
              />
            </v-col>
            <v-col cols="12" sm="8" md="9" xl="10">
              <v-card class="mx-auto elevation-0" color="transparent" outlined>
                <v-card-title class="mt-10 mb-0 pt-0">
                  <h3 class="font-weight-light grey--text">
                    {{ state.item.name }}
                  </h3>
                  <v-spacer />
                </v-card-title>
                <v-card-title class="header font-weight-light grey--text mt-0 mb-0 pt-0">
                  {{ state.item.email }}
                  <v-spacer />
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-card-text>
            <v-expansion-panels popout rounded>
              <v-expansion-panel>
                <v-expansion-panel-header
                  disable-icon-rotate
                  class="text-capitalize text-left font-weight-light"
                  expand-icon="mdi-card-account-details-outline"
                  >User Profile</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-card flat tile v-if="state.changeNameLoading">
                    <v-card-title class="text-capitalize font-weight-light">
                      <v-spacer />
                      <v-progress-circular
                        indeterminate
                        width="1"
                        size="100"
                        color="grey"
                      ></v-progress-circular>
                      <v-spacer />
                    </v-card-title>
                  </v-card>
                  <v-card flat tile v-else>
                    <v-card-title class="text-capitalize font-weight-light">
                      <v-spacer />
                      <v-icon large class="mr-3">mdi-card-account-details-outline</v-icon>
                      {{ $t('commons.titles.userProfile') }}
                      <v-spacer />
                    </v-card-title>
                    <v-divider class="mx-10" />
                    <v-card-text>
                      <span class="subtitle-1 font-weight-light">Name</span>
                      <v-text-field
                        flat
                        dense
                        solo-inverted
                        :value="state.item.name"
                        @input="actions.updateAttribute({ key: 'item.name', value: $event })"
                        :rules="[(v) => !!v || $t('commons.rules.errorRequired')]"
                        required
                        readonly
                      />
                      <span class="subtitle-1 font-weight-light">E-mail</span>
                      <v-text-field
                        flat
                        dense
                        solo-inverted
                        hide-details
                        :value="state.item.email"
                        readonly
                      />
                    </v-card-text>
                    <!-- <v-card-actions>
                      <v-spacer></v-spacer>
                      <pcc-button-tooltip
                        v-on:click="actions.changeName()"
                        icon="mdi-check-all"
                        position="bottom"
                        tooltip="Update"
                        class="primary--text"
                      />
                    </v-card-actions> -->
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  class="text-capitalize text-left font-weight-light"
                  expand-icon="mdi-lock-check-outline"
                  disable-icon-rotate
                  >Change Password</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-card flat tile v-if="state.changePasswordLoading">
                    <v-card-title class="text-capitalize font-weight-light">
                      <v-spacer />
                      <v-progress-circular
                        indeterminate
                        width="1"
                        size="100"
                        color="grey"
                      ></v-progress-circular>
                      <v-spacer />
                    </v-card-title>
                  </v-card>
                  <v-card flat tile v-else>
                    <v-card-title class="text-capitalize font-weight-light">
                      <v-spacer />
                      <v-icon large class="mr-3">mdi-lock-check-outline</v-icon>
                      {{ $t('commons.titles.changePassword') }}
                      <v-spacer />
                    </v-card-title>
                    <v-divider class="mx-10" />
                    <v-card-text>
                      <v-form ref="changePassword" lazy-validation>
                        <span class="subtitle-1 font-weight-light">Password</span>
                        <v-text-field
                          :value="state.password"
                          @input="actions.updateAttribute({ key: 'password', value: $event })"
                          v-bind:hint="$t('singIn.passwordRulesMinLength')"
                          :rules="[
                            (v) => !!v || $t('singIn.passwordRulesRequired'),
                            (v) => v.length >= 8 || $t('singIn.passwordRulesMinLength'),
                          ]"
                          min="8"
                          type="password"
                          clearable
                          required
                          flat
                          dense
                          solo-inverted
                        />
                        <span class="subtitle-1 font-weight-light">New Password</span>
                        <v-text-field
                          :value="state.newPassword"
                          @input="actions.updateAttribute({ key: 'newPassword', value: $event })"
                          v-bind:hint="$t('singIn.passwordRulesMinLength')"
                          :rules="[
                            (v) => !!v || $t('singIn.newPasswordRulesRequired'),
                            (v) => v.length >= 8 || $t('singIn.passwordRulesMinLength'),
                          ]"
                          min="8"
                          type="password"
                          clearable
                          required
                          flat
                          dense
                          solo-inverted
                        />
                        <span class="subtitle-1 font-weight-light">Confirm Password</span>
                        <v-text-field
                          :value="state.confirmPassword"
                          @input="
                            actions.updateAttribute({ key: 'confirmPassword', value: $event })
                          "
                          v-bind:hint="$t('singIn.passwordRulesMinLength')"
                          :rules="[
                            (v) => v === My.state.newPassword || 'Passwords do not match',
                            (v) => !!v || $t('singIn.newPasswordRulesRequired'),
                            (v) => v.length >= 8 || $t('singIn.passwordRulesMinLength'),
                          ]"
                          min="8"
                          type="password"
                          clearable
                          required
                          flat
                          dense
                          solo-inverted
                        />
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <span
                        v-if="state.changePasswordError && state.notMatchCurrentPassword"
                        class="red--text"
                        >Password does not match current password.</span
                      >
                      <span v-else-if="state.changePasswordError" class="red--text"
                        >Error changing password, please try again.</span
                      >
                      <v-spacer></v-spacer>
                      <pcc-button-tooltip
                        v-on:click="actions.changePassword()"
                        icon="mdi-check-all"
                        position="bottom"
                        tooltip="Change password & sing in"
                        class="primary--text"
                      />
                    </v-card-actions>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  class="text-capitalize text-left font-weight-light"
                  :expand-icon="Icons.twoFA"
                  disable-icon-rotate
                  ><span
                    >{{ $t('commons.titles.security') }}
                    <v-icon v-if="!My.state.twoFA.isEnabled" class="mr-3" color="error">{{
                      Icons.alertCircle
                    }}</v-icon></span
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <two-fa-setup />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  class="text-capitalize text-left font-weight-light"
                  expand-icon="mdi-domain"
                  disable-icon-rotate
                  >Account Information</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-card flat tile>
                    <v-card-title class="text-capitalize font-weight-light">
                      <v-spacer />
                      <v-icon large class="mr-3">mdi-domain</v-icon>
                      {{ $t('commons.titles.accountInformation') }}
                      <v-spacer />
                    </v-card-title>
                    <v-divider class="mx-10" />
                    <v-card-text>
                      <v-form ref="accountInformation" lazy-validation>
                        <span class="subtitle-1 font-weight-light">Company</span>
                        <v-text-field
                          flat
                          dense
                          solo-inverted
                          hide-details
                          :value="state.item.name"
                          readonly
                        />
                        <span class="subtitle-1 font-weight-light">E-Mail</span>
                        <v-text-field
                          flat
                          dense
                          solo-inverted
                          hide-details
                          :value="state.item.email"
                          readonly
                        />
                        <span class="subtitle-1 font-weight-light">Address</span>
                        <v-text-field
                          flat
                          dense
                          solo-inverted
                          hide-details
                          :value="state.item.address"
                          @input="actions.updateAttribute({ key: 'item.address', value: $event })"
                        />
                        <span class="subtitle-1 font-weight-light">City</span>
                        <v-text-field
                          flat
                          dense
                          solo-inverted
                          hide-details
                          :value="state.item.city"
                          @input="actions.updateAttribute({ key: 'item.city', value: $event })"
                        />
                        <span class="subtitle-1 font-weight-light">ZIP Code</span>
                        <v-text-field
                          flat
                          dense
                          solo-inverted
                          hide-details
                          :value="state.item.zip"
                          @input="actions.updateAttribute({ key: 'item.zip', value: $event })"
                        />
                        <span class="subtitle-1 font-weight-light">Country</span>
                        <v-autocomplete
                          :value="state.item._country"
                          :items="Countries.state.items"
                          item-text="name"
                          item-value="code"
                          label="Country"
                          solo
                          chips
                          hide-details
                          dense
                          flat
                          solo-inverted
                          clearable
                          prepend-inner-icon="mdi-cursor-pointer"
                          @input="actions.updateAttribute({ key: 'item.country', value: $event })"
                        >
                        </v-autocomplete>
                        <span class="subtitle-1 font-weight-light">Phone</span>
                        <v-text-field
                          flat
                          dense
                          solo-inverted
                          hide-details
                          :value="state.item.phone"
                          @input="actions.updateAttribute({ key: 'item.phone', value: $event })"
                        />
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <pcc-button-tooltip
                        :loading="state.accountInformationLoading"
                        v-on:click="actions.changeAccountInformation()"
                        icon="mdi-check-all"
                        position="bottom"
                        tooltip="Change account information"
                        class="primary--text"
                      />
                    </v-card-actions>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  class="text-capitalize text-left font-weight-light"
                  expand-icon="mdi-account-cash-outline"
                  disable-icon-rotate
                >
                  {{ $t('commons.titles.paymentSettings') }}</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-card flat tile v-if="state.changeBillingSettingsLoading">
                    <v-card-title class="text-capitalize font-weight-light">
                      <v-spacer />
                      <v-progress-circular
                        indeterminate
                        width="1"
                        size="100"
                        color="grey"
                      ></v-progress-circular>
                      <v-spacer />
                    </v-card-title>
                  </v-card>
                  <v-card flat tile v-else>
                    <v-card-title class="text-capitalize font-weight-light">
                      <v-spacer />
                      <v-icon large class="mr-3">mdi-account-cash-outline</v-icon>
                      {{ $t('commons.titles.paymentSettings') }}
                      <v-spacer />
                    </v-card-title>
                    <v-divider class="mx-10" />
                    <v-card-text>
                      <v-form ref="changeBillingSettings" lazy-validation>
                        <span class="subtitle-1 font-weight-light">Currency</span>
                        <v-text-field
                          flat
                          dense
                          solo-inverted
                          hide-details
                          :value="state.item.currency"
                          readonly
                          class="mb-3"
                        />
                        <span class="subtitle-1 font-weight-light">{{
                          $t('billing.fields.paymentFrequency')
                        }}</span>
                        <v-text-field
                          flat
                          dense
                          solo-inverted
                          hide-details
                          :value="state.item.billingPeriod"
                          readonly
                          class="mb-3"
                        />
                        <span class="subtitle-1 font-weight-light">VAT / Tax ID</span>
                        <v-text-field
                          :value="state.item.vatTaxID"
                          @input="actions.updateAttribute({ key: 'item.vatTaxID', value: $event })"
                          :counter="50"
                          :rules="[
                            (v) => !!v || $t('commons.rules.errorRequired'),
                            (v) =>
                              (v && v.length <= 50) ||
                              $t('commons.rules.errorMaxLength', { count: 50 }),
                          ]"
                          required
                          clearable
                          solo-inverted
                          flat
                          dense
                        />
                        <span class="subtitle-1 font-weight-light">Payment method</span>
                        <v-autocomplete
                          v-model="state.item.paymentMethod"
                          :items="state.paymentMethods"
                          solo
                          chips
                          dense
                          flat
                          solo-inverted
                          @input="
                            actions.updateAttribute({ key: 'item.paymentMethod', value: $event })
                          "
                          :rules="[(v) => !!v || $t('commons.rules.errorRequired')]"
                          required
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              small
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              class="ma-1"
                              color="primary"
                            >
                              {{ data.item | upperCase }}
                            </v-chip>
                          </template>
                          <template v-slot:item="data">
                            <v-chip
                              small
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              class="ma-1"
                            >
                              {{ data.item | upperCase }}
                            </v-chip>
                          </template>
                        </v-autocomplete>
                        <v-card
                          flat
                          tile
                          v-if="
                            state.item.paymentMethod &&
                            state.item.paymentMethod.includes('WIRE_TRANSFER')
                          "
                        >
                          <v-card-text>
                            <span class="subtitle-1 font-weight-light">Beneficiary</span>
                            <v-text-field
                              :value="state.item.wireTransferBeneficiary"
                              @input="
                                actions.updateAttribute({
                                  key: 'item.wireTransferBeneficiary',
                                  value: $event,
                                })
                              "
                              :counter="30"
                              :rules="[
                                (v) => !!v || $t('commons.rules.errorRequired'),
                                (v) =>
                                  (v && v.length <= 30) ||
                                  $t('commons.rules.errorMaxLength', { count: 30 }),
                              ]"
                              required
                              clearable
                              solo-inverted
                              flat
                              dense
                            />
                            <span class="subtitle-1 font-weight-light">Bank Name</span>
                            <v-text-field
                              :value="state.item.wireTransferBankName"
                              @input="
                                actions.updateAttribute({
                                  key: 'item.wireTransferBankName',
                                  value: $event,
                                })
                              "
                              :counter="30"
                              :rules="[
                                (v) => !!v || $t('commons.rules.errorRequired'),
                                (v) =>
                                  (v && v.length <= 30) ||
                                  $t('commons.rules.errorMaxLength', { count: 30 }),
                              ]"
                              required
                              clearable
                              solo-inverted
                              flat
                              dense
                            />
                            <span class="subtitle-1 font-weight-light">IBAN / Account Number</span>
                            <v-text-field
                              :value="state.item.wireTransferIBAN"
                              @input="
                                actions.updateAttribute({
                                  key: 'item.wireTransferIBAN',
                                  value: $event,
                                })
                              "
                              :rules="[
                                (v) => !!v || $t('commons.rules.errorRequired'),
                                (v) => /^[0-9a-zA-Z]+$/.test(v) || $t('commons.rules.errorIban'),
                              ]"
                              clearable
                              required
                              flat
                              dense
                              solo-inverted
                            />
                            <span class="subtitle-1 font-weight-light">SWIFT</span>
                            <v-text-field
                              :value="state.item.wireTransferSWIFT"
                              @input="
                                actions.updateAttribute({
                                  key: 'item.wireTransferSWIFT',
                                  value: $event,
                                })
                              "
                              :rules="[(v) => !!v || $t('commons.rules.errorRequired')]"
                              clearable
                              required
                              flat
                              dense
                              solo-inverted
                            />
                            <span class="subtitle-1 font-weight-light">Invoice Threshold</span>
                            <v-text-field
                              flat
                              dense
                              solo-inverted
                              hide-details
                              :value="state.item.wireTransferThreshold"
                              readonly
                              class="mb-3"
                            />
                          </v-card-text>
                        </v-card>
                        <v-card
                          flat
                          tile
                          v-if="
                            state.item.paymentMethod && state.item.paymentMethod.includes('PAYPAL')
                          "
                        >
                          <v-card-text>
                            <span class="subtitle-1 font-weight-light">Paypal Email</span>
                            <v-text-field
                              :value="state.item.paypalEmail"
                              @input="
                                actions.updateAttribute({ key: 'item.paypalEmail', value: $event })
                              "
                              :rules="[
                                (v) => !!v || $t('singIn.emailRulesRequired'),
                                (v) =>
                                  /^(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+(\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/.test(
                                    v
                                  ) || $t('singIn.emailRulesInvalid'),
                              ]"
                              solo-inverted
                              flat
                              dense
                              clearable
                              required
                            ></v-text-field>
                            <span class="subtitle-1 font-weight-light">Invoice Threshold</span>
                            <v-text-field
                              flat
                              dense
                              solo-inverted
                              hide-details
                              :value="state.item.paypalThreshold"
                              readonly
                              class="mb-3"
                            />
                          </v-card-text>
                        </v-card>
                        <v-card
                          flat
                          tile
                          v-if="
                            state.item.paymentMethod && state.item.paymentMethod.includes('PAXUM')
                          "
                        >
                          <v-card-text>
                            <span class="subtitle-1 font-weight-light">PAXUM Email</span>
                            <v-text-field
                              :value="state.item.paxumEmail"
                              @input="
                                actions.updateAttribute({ key: 'item.paxumEmail', value: $event })
                              "
                              :rules="[
                                (v) => !!v || $t('singIn.emailRulesRequired'),
                                (v) =>
                                  /^(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+(\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/.test(
                                    v
                                  ) || $t('singIn.emailRulesInvalid'),
                              ]"
                              solo-inverted
                              flat
                              dense
                              clearable
                              required
                            ></v-text-field>
                            <span class="subtitle-1 font-weight-light">Invoice Threshold</span>
                            <v-text-field
                              flat
                              dense
                              solo-inverted
                              hide-details
                              :value="state.item.paxumThreshold"
                              readonly
                              class="mb-3"
                            />
                          </v-card-text>
                        </v-card>
                        <v-card flat tile v-if="state.item.paymentMethod == 'PAYONEER'">
                          <v-card-text>
                            <span class="subtitle-1 font-weight-light">Payoneer Email</span>
                            <v-text-field
                              v-model="state.item.payoneerEmail"
                              @input="
                                actions.updateAttribute({
                                  key: 'item.payoneerEmail',
                                  value: $event,
                                })
                              "
                              :rules="[
                                (v) => !!v || $t('singIn.emailRulesRequired'),
                                (v) => emailRegex.test(v) || $t('singIn.emailRulesInvalid'),
                              ]"
                              solo-inverted
                              flat
                              dense
                              clearable
                              required
                            ></v-text-field>
                          </v-card-text>
                        </v-card>
                        <v-card
                          flat
                          tile
                          v-if="
                            state.item.paymentMethod &&
                            state.item.paymentMethod.includes('CRYPTOCURRENCY')
                          "
                        >
                          <v-card-text>
                            <span class="subtitle-1 font-weight-light">Cryptocurrency</span>
                            <v-autocomplete
                              v-model="state.item.cryptocurrency"
                              :items="['USDC']"
                              solo
                              chips
                              dense
                              flat
                              solo-inverted
                              @input="
                                actions.updateAttribute({
                                  key: 'item.cryptocurrency',
                                  value: $event,
                                })
                              "
                              :rules="[(v) => !!v || $t('commons.rules.errorRequired')]"
                              required
                            />
                            <span v-if="state.item.cryptocurrency == 'USDC'">
                              <span class="subtitle-1 font-weight-light">Blockchain</span>
                              <v-autocomplete
                                :value="state.item.blockchain"
                                :items="blockchain"
                                label="Blockchain"
                                item-text="name"
                                item-value="value"
                                solo
                                chips
                                dense
                                flat
                                solo-inverted
                                @input="
                                  actions.updateAttribute({ key: 'item.blockchain', value: $event })
                                "
                                :rules="[(v) => !!v || $t('commons.rules.errorRequired')]"
                                required
                            /></span>
                            <span class="subtitle-1 font-weight-light">Wallet Address</span>
                            <v-text-field
                              :value="state.item.walletAddress"
                              @input="
                                actions.updateAttribute({
                                  key: 'item.walletAddress',
                                  value: $event,
                                })
                              "
                              :rules="[(v) => !!v || $t('commons.rules.errorRequired')]"
                              clearable
                              required
                              flat
                              dense
                              solo-inverted
                            />
                            <span class="subtitle-1 font-weight-light">Invoice Threshold</span>
                            <v-text-field
                              flat
                              dense
                              solo-inverted
                              hide-details
                              :value="state.item.cryptocurrencyThreshold"
                              readonly
                              class="mb-3"
                            />
                          </v-card-text>
                        </v-card>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <span v-if="state.changeBillingSettingsError" class="red--text"
                        >Error changing billing settings, please try again.</span
                      >
                      <v-spacer></v-spacer>
                      <pcc-button-tooltip
                        v-on:click="actions.changeBillingSettings()"
                        icon="mdi-check-all"
                        position="bottom"
                        tooltip="Change billing settings"
                        class="primary--text"
                      />
                    </v-card-actions>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel eager>
                <v-expansion-panel-header
                  class="text-capitalize text-left font-weight-light"
                  expand-icon="mdi-console-network-outline"
                  disable-icon-rotate
                  >Global Third-Party Tracking</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <div class="text-center">
                    <span style="font-size: 13px; font-weight: 300">
                      The Third-Party Tracking items added here are Global, this means they will be
                      fired on each conversion reached in any of the promoted offers.
                      <br /><br />
                    </span>
                  </div>
                  <GlobalAffiliatePixels />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  class="text-capitalize text-left font-weight-light"
                  expand-icon="mdi-api"
                  disable-icon-rotate
                  >API Integration</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-card flat tile v-if="state.resetApiKeyLoading">
                    <v-card-title class="text-capitalize font-weight-light">
                      <v-spacer />
                      <v-progress-circular
                        indeterminate
                        width="1"
                        size="100"
                        color="grey"
                      ></v-progress-circular>
                      <v-spacer />
                    </v-card-title>
                  </v-card>
                  <v-card flat tile v-else>
                    <v-card-title class="text-capitalize font-weight-light">
                      <v-spacer />
                      <v-icon large class="mr-3">mdi-api</v-icon>
                      {{ $t('commons.titles.apiIntegration') }}
                      <v-spacer />
                    </v-card-title>
                    <v-divider class="mx-10" />
                    <v-card-text>
                      This API requires authentication using the API key passed in your requests to
                      server.
                    </v-card-text>
                    <v-card-title>
                      <span class="subtitle-1 font-weight-light">Api key</span>
                    </v-card-title>
                    <v-card-text>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-on="on"
                            depressed
                            label
                            class="font-weight-regular"
                            color="blue lighten-1"
                            dark
                            @click="copyToClipboard(`${state.item.apiKey}`)"
                          >
                            <span>Copy</span>
                            <v-icon class="mx-3">mdi-key</v-icon>
                            {{ state.item.apiKey }}
                          </v-chip>
                        </template>
                        <span>{{ state.item.apiKey }}</span>
                      </v-tooltip>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        depressed
                        @click="showAPIKeyResetConfirmationModal = true"
                        class="text-capitalize"
                        >Reset API key</v-btn
                      >
                    </v-card-actions>
                    <v-dialog v-model="showAPIKeyResetConfirmationModal" width="450" persistent>
                      <v-card>
                        <v-card-text>
                          <v-row class="pt-5">
                            <v-col>
                              {{ $t('model.Profile.apiResetMessage') }}
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="text-right">
                              <v-btn @click="showAPIKeyResetConfirmationModal = false" class="mr-4"
                                >Cancel</v-btn
                              >
                              <v-btn
                                @click="
                                  [
                                    actions.resetApiKey(),
                                    (showAPIKeyResetConfirmationModal = false),
                                  ]
                                "
                                color="primary"
                                >Yes</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-dialog
              v-model="showTokenModal"
              class="ma-16 max-z-index"
              max-width="450px"
              min-width="400px"
              persistent
            >
              <two-fa-token-modal
                @canceling="handleTwoFaCancel"
                @token="handleTwoFaToken"
                :error-msg="tokenInvalidMsg"
                :is-validating-token="isValidatingToken"
              />
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { computed, inject, onMounted } from '@vue/composition-api';
import _My from '../../store/private/My';
import PccButtonTooltip from '../Components/PccButtonTooltip';
import PccButtonEditDelete from '../Components/PccButtonEditDelete';
import PccEventNotifications from '../Components/PccEventNotifications';
import CropperComponent from '../Components/CropperComponent';
import GlobalAffiliatePixels from './GlobalAffiliatePixels';
import Icons from '../../icons';
import TwoFaSetup from '../Components/TwoFaSetup';
import TwoFaTokenModal from '@/view/Components/TwoFaTokenModal.vue';

const emailRegex = /^\w+(?:[.-]\w+)*@\w+(?:[.-]\w+)*(\.\w{2,8})+$/;

export default {
  name: 'affiliate-my',
  components: {
    'pcc-button-tooltip': PccButtonTooltip,
    'pcc-button-edit-delete': PccButtonEditDelete,
    'pcc-event-notifications': PccEventNotifications,
    'cropper-component': CropperComponent,
    GlobalAffiliatePixels,
    TwoFaSetup,
    TwoFaTokenModal,
  },
  data: function () {
    return {
      Icons,
      blockchain: [
        {
          name: 'Algorand (ALGO)',
          value: 'Algorand (ALGO)',
        },
        {
          name: 'Arbitrum (ARB)',
          value: 'Arbitrum (ARB)',
        },
        {
          name: 'Avalanche (AVAX)',
          value: 'Avalanche (AVAX)',
        },
        {
          name: 'Base (BASE)',
          value: 'Base (BASE)',
        },
        {
          name: 'Ethereum (ETH)',
          value: 'Ethereum (ETH)',
        },
        {
          name: 'Flow (FLOW)',
          value: 'Flow (FLOW)',
        },
        {
          name: 'Hedera (HBAR)',
          value: 'Hedera (HBAR)',
        },
        {
          name: 'Near (NEAR)',
          value: 'Near (NEAR)',
        },
        {
          name: 'Noble (NOB)',
          value: 'Noble (NOB)',
        },
        {
          name: 'Optimism (OP)',
          value: 'Optimism (OP)',
        },
        {
          name: 'Polkadot Asset Hub (DOT)',
          value: 'Polkadot Asset Hub (DOT)',
        },
        {
          name: 'Polygon (MATIC)',
          value: 'Polygon (MATIC)',
        },
        {
          name: 'Solana (SOL)',
          value: 'Solana (SOL)',
        },
        {
          name: 'Stellar (XLM)',
          value: 'Stellar (XLM)',
        },
      ],
      showAPIKeyResetConfirmationModal: false,
    };
  },
  setup(props, context) {
    const Countries = inject('Countries');
    const application = inject('Application');
    const My = _My(props, context);
    onMounted(async () => {
      await Promise.all([My.actions.loadInfo()]);
      Countries.actions.loadItems();
      My.actions.resetPasswordError();
    });
    const changeThumbnail = async (url) => {
      application.state.user.thumbnail = url;
      await My.actions.changeThumbnail(url);
    };
    const copyToClipboard = (text) => {
      if (navigator.clipboard) navigator.clipboard.writeText(text);
    };
    const tokenInvalidMsg = computed(() => {
      return My.state.twoFA.tokenInvalidMsg || null;
    });
    const isValidatingToken = computed(() => {
      return My.state.twoFA.isValidatingToken || false;
    });
    const showTokenModal = computed(() => {
      return My.state.twoFA.showPasswordTokenModal || My.state.twoFA.showBillingTokenModal;
    });

    return {
      Countries,
      application,
      ...My,
      My,
      changeThumbnail,
      copyToClipboard,
      emailRegex,
      tokenInvalidMsg,
      isValidatingToken,
      showTokenModal,
    };
  },
  methods: {
    closeTwoFaTokenModal() {
      this.My.state.twoFA.showPasswordTokenModal = false;
      this.My.state.twoFA.showBillingTokenModal = false;
    },
    handleTwoFaCancel() {
      this.closeTwoFaTokenModal();
      this.My.actions.resetTwoFaState();
    },
    handleTwoFaToken(token) {
      if (this.My.state.twoFA.showPasswordTokenModal) {
        this.My.actions.changePassword(token);
      } else if (this.My.state.twoFA.showBillingTokenModal) {
        this.My.actions.changeBillingSettings(token);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.v-avatar--offset {
  top: -38px;
  position: relative;
}
</style>
